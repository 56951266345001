/* eslint react/forbid-prop-types: 0 */

import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';

import Loading from './containers/Loading';

import {
  AsyncBuyer,
} from './RouteLoaders';

const Routes = () => (
  <Route
    render={({ location }) => (
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Switch location={location}>
            <Route path="/" component={AsyncBuyer} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    )}
  />
);

export default Routes;
