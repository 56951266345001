import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../../Routes';

import './styles.css';

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </div>
);

export default App;
