/* eslint-disable react/jsx-filename-extension */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import App from './containers/App';
import config from './config';
import './index.css';

const bugsnagClient = bugsnag({
  apiKey: 'aa93e6c180328864d05e36cec3a3d7cd',
  releaseStage: config.bugsnag.RELEASE_STAGE,
  appVersion: config.app.version,
});
bugsnagClient.use(bugsnagReact, React);
window.bugsnagClient = bugsnagClient;

const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);
